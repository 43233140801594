import ScatterplotComponent from "components/scatterplots/ScatterplotComponent";
import React from "react";

function ScatterplotPage() {
  return (
    <div className="w-full">
      <ScatterplotComponent />
    </div>
  );
}
export default ScatterplotPage;
