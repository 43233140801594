import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ActiveElement,
  Title,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { getAnswersWithPaginationByProject } from "services/answerService";
import { useNavigate, useParams } from "react-router-dom";
import { handleError } from "utils/handleError";
import { Answer } from "interfaces/Answer";
import { Loading } from "utils/Loading";
import { isAuthenticated } from "services/authService";
import InfoModalComponent from "./modals/InfoModalComponent";
import { ChartData } from "interfaces/ChartData";
import AnswerTableComponent from "components/answers/AnswerTableComponent";
import PaginationComponent from "components/layout/nav/PaginationComponent";
import { Pagination } from "interfaces/Pagination";
import LoginModalComponent from "components/auth/modals/LoginModalComponent";
import SignUpModalComponent from "components/auth/modals/SignUpModalComponent";
import { toast } from "react-toastify";
import { getMe } from "services/userService";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, annotationPlugin, Title);

function ScatterplotComponent() {
  const authenticated = isAuthenticated();
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ChartData | null>(null);
  const [pagination, setPagination] = useState<Pagination>();
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false); // État pour gérer l'ouverture de la modale de connexion
  const [openSignUpModal, setOpenSignUpModal] = useState<boolean>(false); // État pour gérer l'ouverture de la modale d'inscription
  const userBasicInfo = sessionStorage.getItem("userBasicInfo");
  const [loginPurpose, setLoginPurpose] = useState<"share" | "save" | null>(null); // État pour stocker l'intention de connexion
  const navigate = useNavigate();

  const handleOpenLoginModal = (purpose: "share" | "save" | null = null) => {
    setLoginPurpose(purpose); // Définit l'intention de connexion ("share", "save", ou null)
  
    // Si la modale d'inscription est ouverte, la fermer d'abord
    if (openSignUpModal) {
      setOpenSignUpModal(false);
      setTimeout(() => {
        setOpenLoginModal(true); // Ouvrir la modale de connexion après fermeture de la modale d'inscription
      }, 50);
    } else {
      setOpenLoginModal(true); // Ouvrir directement la modale de connexion si aucune modale n'est ouverte
    }
  };

  const handleOpenSignUpModal = (purpose: "share" | "save" | null = null) => {
    setLoginPurpose(purpose); // Stocke l'intention d'inscription ("share" ou "save")
    if (openLoginModal) {
      setOpenLoginModal(false);
      setTimeout(() => {
        setOpenSignUpModal(true);
      }, 50);
    } else {
      setOpenSignUpModal(true);
    }
  };

  const handleCloseModals = () => {
    setOpenLoginModal(false); // Ferme la modale de connexion
    setOpenSignUpModal(false); // Ferme la modale d'inscription
  };

  const openInfoModal = (answer: ChartData) => {
    setModalData(answer);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setModalData(null);
    setOpenModal(false);
  };

  // State pour stocker les données du graphique et les noms de catégories
  const [answersWithCategorySum, setAnswersWithCategorySum] = useState<
    ChartData[] | undefined
  >(undefined);
  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  const fetchData = async (page?: number) => {
    try {
      if (!projectId) {
        return;
      }

      const response = await getAnswersWithPaginationByProject(projectId, page);
      const answersData: Answer[] = response.data;
      setPagination(response.meta);

      // Transfation des réponses en données utilisables pour le graphique
      const answersWithSum = answersData.map((answer: Answer) => {
        const data: { [categoryId: string]: number } = {};

        // Parcoure les réponses de chaque question pour agréger les valeurs par catégorie
        Object.values(answer.answers).forEach((response) => {
          const categoryName = response.category_name;
          const responseValue = response.response;
          if (data[categoryName]) {
            data[categoryName] += responseValue;
          } else {
            data[categoryName] = responseValue;
          }
        });

        // On renvoi un objet avec l'email et les données agrégées
        return { ...answer, data };
      });

      // Maj du state avec les données du graphique
      setAnswersWithCategorySum(answersWithSum);

      // On extraie les noms de catégorie de la première réponse
      const firstAnswer = answersWithSum[0];
      if (firstAnswer) {
        const names = Object.keys(firstAnswer.data);
        setCategoryNames(names);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userEmailToHighlight = localStorage.getItem("answerEmail");

  const options: any = {
    scales: {
      x: {
        min: 10,
        max: 50,
        title: {
          display: true,
          text: "Complexité du projet pricing",
        },
        grid: {
          lineWidth: (context: any) => (context.tick.value === 30 ? 3 : 1), // Ajustez la largeur de la ligne de la grille de l'axe x
        },
        ticks: {
          stepSize: 10, // Définit l'intervalle entre les lignes de la grille à 10
        },
      },
      y: {
        min: 10,
        max: 50,
        title: {
          display: true,
          text: "Complexité du changement dans l'entreprise",
        },
        grid: {
          lineWidth: (context: any) => (context.tick.value === 30 ? 3 : 1), // Ajustez la largeur de la ligne de la grille de l'axe x
        },
        ticks: {
          stepSize: 10, // Définit l'intervalle entre les lignes de la grille à 10
        },
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    plugins: {
      tooltip: {
        enabled: authenticated ? true : false,
        callbacks: {
          label: (context: any) => {
            const answer = answersWithCategorySum
              ? answersWithCategorySum[context.dataIndex]
              : null;

            // Retournez l'email dans le label du tooltip
            return answer ? `${answer.email}` : "";
          },
        },
      },
      legend: {
        display: false, // Désactive la légende
      },
      title: {
        display: true, // Affiche le titre
        text: 'Intensité prévisionnelle de la gestion du changement', // Le texte du titre
        font: {
          size: 18, // Taille de la police du titre
        },
      },
      annotation: {
        annotations: {
          lineModerate: {
            type: 'line',
            xMin: 30, // Point de départ sur l'axe X
            yMin: 10, // Point de départ sur l'axe Y
            xMax: 10, // Point de fin sur l'axe X
            yMax: 30, // Point de fin sur l'axe Y
            borderColor: '#2BD6D9',
            borderWidth: 3,
          },
          lineCritic: {
            type: 'line',
            xMin: 50, // Point de départ sur l'axe X
            yMin: 20, // Point de départ sur l'axe Y
            xMax: 20, // Point de fin sur l'axe X
            yMax: 50, // Point de fin sur l'axe Y
            borderColor: '#2BD6D9',
            borderWidth: 3,
          },
          labelModerate: {
            type: 'label',
            xValue: 15,
            yValue: 15,
            content: ['Modérée'],
            font: {
              size: 18,
              family: 'Segoe UI',
            }
          },
          labelImportant: {
            type: 'label',
            xValue: 28,
            yValue: 28,
            content: ['Importante'],
            font: {
              size: 18,
              family: 'Segoe UI',
            }
          },
          labelCritic: {
            type: 'label',
            xValue: 45,
            yValue: 45,
            content: ['Critique'],
            font: {
              size: 18,
              family: 'Segoe UI',
            }
          }
        },
      },
    },
    onClick: (event: any, elements: ActiveElement[]) => {
      if (authenticated) {
        if (elements && elements.length > 0) {
          const index = elements[0].index;
          const answer = answersWithCategorySum
            ? answersWithCategorySum[index]
            : null;

          if (answer) {
            openInfoModal(answer);
          }
        }
      }
    },
    onHover: (event: any, elements: ActiveElement[]) => {
      if (authenticated) {
        elements.length > 0
          ? (event.chart.canvas.style.cursor = "pointer")
          : (event.chart.canvas.style.cursor = "default");
      }
    },
  };

  // Données du graphique
  const chartData = {
    datasets: [
      {
        data:
          answersWithCategorySum?.map((answer) => ({
            x: Object.values(answer.data)[0] || 0,
            y: Object.values(answer.data)[1] || 0,
          })) || [],
        backgroundColor: answersWithCategorySum?.map((answer) =>
          answer.email === userEmailToHighlight
            ? "#2BD6D9"
            : authenticated
            ? "rgb(255, 99, 132)"
            : "grey"
        ),
      },
    ],
  };

  const handleLoginSuccess = async (purpose: "share" | "save" | null) => {
    try {
      const user = await getMe();
      if ((purpose === "share" || purpose === null) && projectId) {
        copyUrl(projectId);
      }
    
      setOpenLoginModal(false); // Ferme la modale après la connexion réussie
      setLoginPurpose(null); // Réinitialise l'intention de connexion
      
      if(user) {
        navigate("/project");
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  const handleSignUpSuccess = async (purpose: "share" | "save" | null) => {
    try {
      const user = await getMe();
      if (user) {
        if ((purpose === "share" || purpose === null) && projectId) {
          copyUrl(projectId);
        }
        
        // Ajoute un délai de 1 seconde avant la redirection pour permettre au toast de s'afficher
        setTimeout(() => {
          window.location.replace("/project");
        }, 2000);
      }
    
      setOpenSignUpModal(false); // Ferme la modale après la connexion réussie
      setLoginPurpose(null); // Réinitialise l'intention de connexion
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  const copyUrl = (
    projectId: string,
  ) => {
    const urlToCopy = `${window.location.origin}/project/${projectId}/quiz`;

    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        toast.success("Lien copié dans le presse papier - envoyez le à vos collègues");
      })
      .catch(() => {
        toast.error("Une erreur est survenue");
      });
  };

  // Rendu du composant avec le graphique Scatter
  return (
    <div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-3/4 py-6 mx-auto flex justify-center">
            <Scatter options={options} data={chartData} />
          </div>
        )}
        {openModal && modalData && (
          <InfoModalComponent
            data={modalData}
            isOpen={openModal}
            onClose={handleCloseModal}
          />
        )}

        {openLoginModal && (
        <LoginModalComponent
          isOpen={openLoginModal}
          onClose={handleCloseModals}
          onSwitchToSignUp={() => handleOpenSignUpModal(loginPurpose)} // Gère la bascule vers la modale d'inscription
          projectId={projectId}
          onLoginSuccess={() => handleLoginSuccess(loginPurpose)} // Gère l'événement de connexion réussie
        />
        )}

        {openSignUpModal && (
          <SignUpModalComponent
            isOpen={openSignUpModal}
            onClose={handleCloseModals}
            onSwitchToLogin={() => handleOpenLoginModal(loginPurpose)} // Gère la bascule vers la modale d'inscription
            projectId={projectId}
            onSignUpSuccess={() => handleSignUpSuccess(loginPurpose)}
          />
        )}
      </div>

      <div className="mb-6 px-2">
        <p><b>Modérée:</b> votre projet induira des changements dans vos processus et méthodes, mais leur étendue est limitée et/ou votre entreprise a les capacités pour y faire face. Ne négligez pas la communication avec les équipes et la formation potentiellement nécessaire pour vous assurer d'une bonne adoption des nouvelles pratiques.</p>
        <p><b>Importante:</b> votre projet induira des changements significatifs, qui nécessitent un accompagnement attentif des parties prenantes impactées par ces changements. Nous vous recommandons d'intégrer la gestion du changement à votre approche projet, sous la direction du responsable du projet, et de former ce dernier ou cette dernière aux bonnes pratiques associées. La compréhension, l'appréciation puis l'appropriation des changements par les utilisateurs doit être monitorée et les alertes levées lors des comités de pilotage.</p>
        <p><b>Critique:</b> votre projet est de nature profondément transformative. La gestion du changement doit faire l'objet d'une attention aiguë et particulière dès le démarrage du projet, et d'un stream de travail à part entière visant à anticiper les points de blocage à différents niveaux de l'organisation et à les résoudre via différentes techniques. N'hésitez pas à vous faire accompagner si les ressources spécialisées en gestion du changement ne sont pas disponibles en interne. Prévoyez tant le budget que le temps nécessaire au buy-in puis à l'adoption dans votre plan projet. La compréhension, l'appréciation puis l'appropriation des changements par les utilisateurs doit être monitorée et les alertes levées lors des comités de pilotage.</p>            
      </div>

      {(authenticated || (!authenticated && userBasicInfo)) && projectId && (          
        <div className="flex flex-col items-center mb-4 px-2">
          <p className="mb-4">Merci de votre participation. Demandez à vos collègues impliqués sur le projet de remplir cette même évaluation et visualisez les résultats</p>
          <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2" 
            onClick={() => {
              if (authenticated) {
                copyUrl(projectId)
              } else {
                handleOpenSignUpModal("share");
              }
            }}>Partager le projet</button>
        </div>
        )}

        {!authenticated && userBasicInfo && (
          <div className="flex flex-col items-center px-2">
            <p className="mb-4">Pas prêt à partager le projet maintenant ? Créez un compte pour sauvegarder votre travail et le partager plus tard :</p>
            <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2" onClick={() => handleOpenSignUpModal("save")}>Sauvegarder le projet</button>
          </div>
        )}

      {!authenticated && !userBasicInfo && (
        <div className="flex flex-col items-center px-2">
          <p className="mb-4">Merci ! Vos réponses ont été enregistrées et transmises.</p>
          <a href="https://converteo.com/strategie-pricing/">
            <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2">En savoir +</button>
          </a>
        </div>
      )}
      
      {authenticated && (
        <>
          <AnswerTableComponent answers={answersWithCategorySum || []} />
          {pagination && <PaginationComponent pagination={pagination} fetchData={fetchData} />}

        </>
      )}
    </div>
  );
}

// Exportez le composant ScatterplotComponent par défaut
export default ScatterplotComponent;
