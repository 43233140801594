import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SignUpFormComponent from "../SignUpFormComponent";

interface MyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToLogin: () => void;
  projectId?: string;
  onSignUpSuccess?: () => void;
}

function SignUpModalComponent({ isOpen, onClose, onSwitchToLogin, projectId, onSignUpSuccess }: MyModalProps) {
   // Fonction de gestion du succès d'inscription
   const handleSignUpSuccess = () => {
    if(onSignUpSuccess) {
      onSignUpSuccess(); // Remonte l'événement de succès de creation au parent de la modale
    }  
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-black/50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-black/15 overflow-y-auto">
            <Dialog.Panel className="w-1/3 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle transition-all">
              <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Créez un compte pour pouvoir retrouver vos résultats et/ou ceux de vos collègues par la suite
              </Dialog.Title>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm my-10">
              <SignUpFormComponent
                  isModal={true}
                  onSignUpSuccess={handleSignUpSuccess} // Sur le succès de l'inscription, déclenche le succès de la connexion
                  projectId={projectId}
                />
                <p className="mt-10 text-center text-sm text-gray-500">
                  Déjà inscrit ?{" "}
                  <span
                    className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    onClick={onSwitchToLogin} // Bascule vers la modale de connexion
                  >
                    Se connecter
                  </span>
                </p>
              </div>          
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SignUpModalComponent;
