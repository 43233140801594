import React, { useRef, useState, RefObject, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import SignUpForm from "../../form-models/SignUpForm";
import ReCAPTCHA from "react-google-recaptcha";
import { registration } from "services/userService";
import { ValidationError, handleError } from "utils/handleError";
import { toast } from "react-toastify";
import { postByUrlGetResponse } from "../../services/services";
import Label from "../../components/layout/form/Label";
import Input from "../../components/layout/form/Input";
import ErrorForm from "../../components/layout/form/ErrorForm";
import Checkbox from "components/layout/form/Checkbox";

interface SignUpFormProps {
  isModal: boolean;
  onSignUpSuccess?: () => void; // Fonction de rappel pour le succès d'inscription
  projectId?: string;
}

// Définir le schéma de validation avec Yup
const signUpSchema = yup.object().shape({
  lastname: yup.string().required("Un nom est requis"),
  firstname: yup.string().required("Un prénom est requis"),
  email: yup.string().required("Un email est requis"),
  password: yup
    .string()
    .required("Un mot de passe est requis")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Doit contenir au minimum 8 caractères, une minuscule, une majuscule, un chiffre et un caractère spécial (!@#$%^&*)"
    ),
  password_confirmation: yup
    .string()
    .required("Une confirmation de mot de passe est requise.")
    .oneOf([yup.ref("password")], "Les mots de passe ne correspondent pas"),
  communication_accepted: yup.bool().optional(),
});

function SignUpFormComponent({ isModal, onSignUpSuccess, projectId }: SignUpFormProps) {
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SignUpForm>({
    resolver: yupResolver(signUpSchema),
    mode: "onChange",
  });

  interface YourCaptchaType {
    getValue(): string;
    reset(): void;
  }

  const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const navigate = useNavigate();
  const [valid_token, setValidToken] = useState<string[] | undefined>([]);
  const captchaRef = useRef<YourCaptchaType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const verifyToken = async (token: string) => {
    try {
      return   await postByUrlGetResponse("api/user/verifyToken", {
        reCAPTCHA_TOKEN: token,
      });
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  useEffect(() => {
    const savedFormData = sessionStorage.getItem("userBasicInfo");
    if (savedFormData) {
      const { lastname, firstname, email } = JSON.parse(savedFormData);
      setValue("lastname", lastname || "");
      setValue("firstname", firstname || "");
      setValue("email", email || "");
      setIsDisabled(true);
    }
  }, []);

  const onSubmit: SubmitHandler<SignUpForm> = async (data) => {
    try {
      // Si le projectId est présent, soumettre le formulaire sans captcha
      if (projectId) {
        const requestData = { 
          ...data, 
          project_id: projectId // Ajouter project_id si présent
        };
  
        await registration(requestData);
  
        if (onSignUpSuccess) {
          onSignUpSuccess(); // Appelle la fonction de succès d'inscription
        }

      } else if (captchaRef.current) { // Captcha requis si projectId n'est pas présent
        let token = captchaRef.current.getValue();
        captchaRef.current.reset();
  
        if (token) {
          const valid_token = await verifyToken(token);
          if (valid_token && valid_token.message === "OK") {
            setValidToken(valid_token);
  
            const requestData = { ...data }; // Ne pas inclure project_id
            await registration(requestData);
  
            navigate("/project");

          } else {
            toast.error("Une erreur de validation est survenue.");
          }
        } else {
          toast.error("Veuillez accepter le captcha pour continuer.");
        }
      }
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };
  
  return (
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label label={"Nom"} required={true} />
              <Input<SignUpForm>
                registerProps={register("lastname")}
                name={"lastname"}
                className="big"
                isDisabled={isDisabled}
              />
              <ErrorForm
                name={"lastname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Prénom"} required={true} />
              <Input<SignUpForm>
                registerProps={register("firstname")}
                name={"firstname"}
                className="big"
                isDisabled={isDisabled}
              />
              <ErrorForm
                name={"firstname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Adresse email"} required={true} />
              <Input<SignUpForm>
                registerProps={register("email")}
                name={"email"}
                type="email"
                className="big"
                isDisabled={isDisabled}
              />
              <ErrorForm
                name={"email"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Mot de passe"} required={true} />
              <Input<SignUpForm>
                registerProps={register("password")}
                type="password"
                name={"password"}
                className="big"
                showToggle={true}
              />
              <ErrorForm
                name={"password"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Confirmation du mot de passe"} required={true} />
              <Input<SignUpForm>
                registerProps={register("password_confirmation")}
                type="password"
                name={"password_confirmation"}
                className="big"
                showToggle={true}
              />
              <ErrorForm
                name={"password_confirmation"}
                errors={errors}
                errValidation={errValidation}
        />
    </div>

    {!projectId && (
      <>
        <div className="flex items-baseline">
            <Checkbox<SignUpForm>
            registerProps={register("communication_accepted")}
            name={"communication_accepted"}
            />
            <ErrorForm
            name={"communication_accepted"}
            errors={errors}
            errValidation={errValidation}
            />
            <Label
            htmlFor="communication_accepted"
            label={
                "Je ne souhaite pas recevoir de communications concernant les offres et produits Converteo"
            }
            required={false}
            />
        </div>

        {site_key && (
            <ReCAPTCHA
            className="recaptcha"
            sitekey={site_key}
            ref={captchaRef as RefObject<ReCAPTCHA>}
            />
        )}

        <p className="text-sm font-medium leading-6 text-gray-900">En cliquant sur confirmer, vous reconnaissez avoir pris connaissance des{" "}
          <Link
          to="/cgu"
          style={{ color: "blue", textDecoration: "underline" }}
          target="_blank"
          >
          CGU
          </Link>{" "}
          et de la{" "}
          <Link
          to="/confidentality"
          style={{ color: "blue", textDecoration: "underline" }}
          target="_blank"
          >
          Politique de confidentialité
          </Link>{" "}
        </p>
      </>
    )}

    <div>
        <button
        type="submit"
        className="flex w-full justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Confirmer
        </button>
    </div>
    </form>
    );
}

export default SignUpFormComponent;
