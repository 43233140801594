import React from "react";
import { ChartData } from "interfaces/ChartData";

interface AnswerTableProps {
  answers: ChartData[];
}

function AnswerTableComponent({ answers }: AnswerTableProps) {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Liste des répondants
      </h1>
      <div className="p-1.5">
        <div className="border rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                  Email
                </th>
                <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                  Nom
                </th>
                <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                  Prénom
                </th>
                {Object.keys(answers[0]?.data || {}).map((category, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                  >
                    {category}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {answers.map((answer) => (
                <tr key={answer.id}>
                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                    {answer.email}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                    {answer.lastname}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                    {answer.firstname}
                  </td>
                  {Object.entries(answer.data).map(
                    ([category, score], index) => (
                      <td
                        key={index}
                        className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                      >
                        {score}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default AnswerTableComponent;
