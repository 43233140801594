import ProjectForm from "form-models/ProjectForm";
import { deleteByUrl, getByUrl, getFullResponseByUrl, postByUrl, updateByUrl } from "./services";

const projectUrl = "api/project";

export const getAllProjects = async () => {
  try {
    return getByUrl(projectUrl);
  } catch (error) {
    throw error;
  }
};

export const getAllProjectsWithPagination = async (page?: number) => {
  try {
    return getFullResponseByUrl(`${projectUrl}${page !== undefined ? `?page=${page}` : ''}`);  
    } catch (error) {
    throw error;
  }
};

export const getAllProjectsArchivedWithPagination = async (page?: number) => {
  try {
    return getFullResponseByUrl(`${projectUrl}/listArchived${page !== undefined ? `?page=${page}` : ''}`);  
    } catch (error) {
    throw error;
  }
};

export const getProjectById = async (projectId: string) => {
  try {
    const url = `${projectUrl}/${projectId}`;
    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const createProject = async (projectData: ProjectForm) => {
  try {
    const url = `${projectUrl}-user`;
    return postByUrl(url, projectData);
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (
  projectData: ProjectForm,
  projectId: string
) => {
  try {
    const url = `${projectUrl}/${projectId}`;
    return updateByUrl(url, projectData);
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (projectId: string) => {
  try {
    const url = `${projectUrl}/${projectId}`;
    return deleteByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const archiveAndUnarchiveProject = async (projectId: string) => {
  try {
    const url = `${projectUrl}/archive/${projectId}`;
    return updateByUrl(url);
  } catch (error) {
    throw error;
  }
};
