import React, { useState } from "react";
import {FieldValues, UseFormRegisterReturn} from 'react-hook-form';
import {
    EyeIcon,
    EyeSlashIcon,
  } from "@heroicons/react/24/solid";

interface InputComponent2Props<T extends FieldValues = FieldValues> {
    registerProps: UseFormRegisterReturn;
    name: string;
    isDisabled?: boolean;
    className?: string;
    type?: string;
    showToggle?: boolean;
}

function Input<T>({registerProps, name, type='text', className = '', isDisabled = false, showToggle = false,}: InputComponent2Props) {
    const [showPassword, setShowPassword] = useState(false);
    
    let classnameCss = `block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6 ${isDisabled ? 'bg-gray-100 cursor-not-allowed' : ''}`;

    if (className === 'big') {
        classnameCss = `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6 ${isDisabled ? 'bg-gray-100 cursor-not-allowed' : ''}`;
    }

    return (
        <div className="mt-2 relative">
            <input
                {...registerProps}
                id={name}
                name={name}
                type={showPassword && showToggle ? "text" : type}
                className={`${classnameCss} ${showToggle ? "pr-10" : ""}`}
                disabled={isDisabled}
            />
            {/* Bouton œil pour afficher/masquer le mot de passe */}
            {showToggle && (
                <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 focus:outline-none focus:ring-transparent"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Masquer le mot de passe" : "Afficher le mot de passe"}
                title={showPassword ? "Masquer le mot de passe" : "Afficher le mot de passe"}
                style={{ background: "transparent", border: "none", cursor: "pointer" }}
                >
                {showPassword ? (
                    <EyeIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                    <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
                )}
                </button>
            )}
        </div>
    );
}

export default Input;