import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div className="flex flex-col align items-center justify-center w-full">
      <div className="my-5">
        <h1 className="text-center">Oops!</h1>
        <p>Désolé, la page que vous demandez n'existe pas.</p>
      </div>

      <Link to="/project">
        <button className="mr-2 rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          Retour à l'accueil
        </button>
      </Link>
    </div>
  );
}
