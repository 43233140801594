import React from "react";
import {
  TagIcon,
  QuestionMarkCircleIcon,
  BeakerIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

interface SidebarProps {
  role: string | null;
}

function SidebarComponent({role}: SidebarProps) {
  const menus = [
    {
      title: "Projet",
      link: "/project",
      icon: BeakerIcon,
      onlyAdmin: false,
    },
    {
      title: "Catégorie",
      link: "/category",
      icon: TagIcon,
      onlyAdmin: true,
    },
    {
      title: "Questionnaire",
      link: "/quiz",
      icon: QuestionMarkCircleIcon,
      onlyAdmin: true,
    },
  ];

  return (
    <div className="w-60 h-auto bg-primary-blue text-white">
<ul>
    {menus.map((menu, index) => (
      (role === "Super Admin" || !menu.onlyAdmin) &&
      <li key={index} className="m-2">
        <Link
          to={menu.link}
          className="text-m flex items-center gap-x-4 cursor-pointer p-2 hover:bg-primary-blue-light rounded-md block"
        >
          <menu.icon className="h-6 w-6" />
          {menu.title}
        </Link>
      </li>
    ))}
  </ul>
    </div>
  );
}
export default SidebarComponent;
