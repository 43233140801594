import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { getAllProjectsArchivedWithPagination, getAllProjectsWithPagination } from "services/projectService";
import { handleError } from "utils/handleError";
import { Loading } from "utils/Loading";
import { Project } from "interfaces/Project";
import ProjectTableComponent from "components/projects/ProjectTableComponent";
import { Pagination } from "interfaces/Pagination";
import PaginationComponent from "components/layout/nav/PaginationComponent";

function ProjectPage() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectsArchived, setProjectsArchived] = useState<Project[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>();
  const [paginationArchived, setPaginationArchived] = useState<Pagination>();
  const tabNames = ["Projets actifs", "Projets archivés"];
  const navigate = useNavigate(); 
  
  const fetchProjectsData = async (page = 1, archivedPage = 1) => {
    setIsLoading(true);
    try {
      const [activeResponse, archivedResponse] = await Promise.all([
        getAllProjectsWithPagination(page),
        getAllProjectsArchivedWithPagination(archivedPage)
      ]);

      setProjects(activeResponse.data);
      setPagination(activeResponse.meta);
      setProjectsArchived(archivedResponse.data);
      setPaginationArchived(archivedResponse.meta);

      if (activeResponse.data.length === 0 && archivedResponse.data.length === 0) {
        navigate("/project/create");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectsData(); // Default fetch on component mount
  }, []);

  // Handle page change for active projects
  const handleActivePageChange = (newPage: number) => {
    fetchProjectsData(newPage, paginationArchived?.current_page || 1);
  };

  // Handle page change for archived projects
  const handleArchivedPageChange = (newPage: number) => {
    fetchProjectsData(pagination?.current_page || 1, newPage);
  };
  
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full">

      <h1 className="p-6 text-2xl font-semibold flex-1">Liste des projets</h1>

      <div className="card">
          <div className="card-body">
            <div className="card-describe">
              <p>Bienvenue sur l’application "Scorecard gestion du changement" de Converteo.  </p>
              <br></br>
              <p>
                Vous pouvez créer un nouveau sondage, en appuyant sur le bouton
                &nbsp;<button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <Link to="create">Créer un projet</Link>
                </button>
              </p>
              <br></br>
              <p>
                Vous pouvez aussi revenir sur un sondage pour l’éditer, y répondre ou envoyer un lien pour que 
                d’autres personnes de votre entreprise y répondent.
              </p>
              <br></br>
              <p>
                Vous pouvez mener plusieurs sondages en parallèle. Une fois une campagne de sondage menée, vous pouvez décider d’archiver le sondage,  en utilisant
                &nbsp;<img style={{ width: "30px", display: "inline"}} src="\toogle.png" alt="toogle"/>.
              </p>
              <p>
                Vous pourrez toujours accéder aux sondages archivés (et éventuellement les remettre en statut « Actif »), en cliquant  sur
                &nbsp;<img style={{ width: "300px", display: "inline"}} src="\archive.png" alt="archive"/>
              </p>
            </div>
          </div>
      </div>

      <div className="p-1.5">
        <div className="flex justify-end mb-2">
          <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <Link to="create">Créer un projet</Link>
          </button>
        </div>
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-gray-300 p-1">
            {tabNames.map((tabName) => (
              <Tab
                key={tabName}
                className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5
          
        ${
          selected
            ? "bg-white shadow cursor-default ring-offset-4 focus:ring-2 ring-primary-cyan focus:outline-none"
            : "text-white hover:text-white"
        }`
                }
              >
                {tabName}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels className="mt-2">
            <Tab.Panel>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <ProjectTableComponent
                    projects={projects}
                    fetchData={fetchProjectsData}
                  />
                  {pagination && <PaginationComponent pagination={pagination} fetchData={handleActivePageChange} />}
                </>
              )}
            </Tab.Panel>
            <Tab.Panel>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <ProjectTableComponent
                    projects={projectsArchived}
                    fetchData={fetchProjectsData}
                  />
                    {paginationArchived  && <PaginationComponent pagination={paginationArchived} fetchData={handleArchivedPageChange} />}
                </>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default ProjectPage;