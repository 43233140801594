import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { getAllCategoriesWithPagination } from "services/categoryService";
import { Category } from "interfaces/Category";
import { Link } from "react-router-dom";
import { handleError } from "utils/handleError";
import { Loading } from "utils/Loading";
import PaginationComponent from "components/layout/nav/PaginationComponent";
import { Pagination } from "interfaces/Pagination";

function CategoryTableComponent() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>();

  // Fonction asynchrone pour effectuer la requête API
  const fetchData = async (page?: number) => {
    try {
      const response = await getAllCategoriesWithPagination(page);

      // Mettre à jour le state avec les données de la réponse
      setCategories(response.data);
      setPagination(response.meta);

    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full">
      <div className="p-1.5">
        <div className="flex justify-end mb-2">
          <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <Link to="create">Créer une catégorie</Link>
          </button>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Nom
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Description
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {categories.map((category: Category) => (
                  <tr key={category.id}>
                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      {category.name}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      <span dangerouslySetInnerHTML={{ __html: category.description || ""}} />
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap flex gap-x-6 items-center justify-center">
                      <div className="has-tooltip">
                        <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
                          Editer
                        </span>
                        <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
                          <Link to={category.id}>
                            <PencilIcon className="h-6 w-6" />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {pagination && <PaginationComponent pagination={pagination} fetchData={fetchData} />}
      </div>
    </div>
  );
}
export default CategoryTableComponent;
