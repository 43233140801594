import React from "react";
import AnswerComponent from "components/answers/AnswerComponent";
function AnswerPageComponent() {
  return (
    <div className="w-full">
      <AnswerComponent />
    </div>
  );
}
export default AnswerPageComponent;
