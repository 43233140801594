import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import LoginForm from "../../form-models/LoginForm";
import { useNavigate } from "react-router-dom";
import { login } from "services/authService";
import { ValidationError, handleError } from "utils/handleError";
import {
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";

// Définir le schéma de validation avec Yup
const loginSchema = yup.object().shape({
  email: yup.string().required("Un email est requis"),
  password: yup.string().required("Un mot de passe est requis"),
});

interface LoginFormProps {
    isModal: boolean;
    onClose?: () => void;
    projectId?: string;
    onLoginSuccess?: () => void;
}

function LoginFormComponent({isModal, onClose, projectId, onLoginSuccess}: LoginFormProps) {
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const savedFormData = sessionStorage.getItem("userBasicInfo");
    if (savedFormData) {
      const { email } = JSON.parse(savedFormData);
      setValue("email", email || "");
      setIsDisabled(true);
    }
  }, []);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const requestData = { 
        ...data, 
        ...(projectId && { project_id: projectId })
      };
            
      const response = await login(requestData);
      
      if (!response?.access_token) return; // Si la connexion échoue, on ne fait rien
  
      if (isModal && onClose) {
        onClose();
      }
      
      if (onLoginSuccess) { 
        onLoginSuccess(); // Met à jour l'état global de `authenticated`
      }

      navigate("/project");

    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };
  
  return (
    <form
        className="space-y-6"
        action="#"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
    >
        <div>
        <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
        >
            Adresse email <span className="text-red">*</span>
        </label>
        <div className="mt-2">
            <input
            {...register("email")}
            id="email"
            name="email"
            type="email"
            disabled={isDisabled}
            autoComplete="email"
            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6 ${ isDisabled ? 'bg-gray-100 cursor-not-allowed' : ''}`}
            />
            <p className="text-red">{errors.email?.message}</p>
            {errValidation?.errors?.email && (
            <p className="text-red">{errValidation.errors.email[0]}</p>
            )}
        </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
              <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
              >
              Password <span className="text-red">*</span>
              </label>
          </div>
          <div className="mt-2 relative">
            <input
              {...register("password")}
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              className="block w-full rounded-md border-0 py-1.5 px-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400"
              onClick={() => setShowPassword(!showPassword)}
              style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
            >
              {showPassword ? (
                <EyeIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
            <p className="text-red">{errors.password?.message}</p>
            {errValidation?.errors?.password && (
              <p className="text-red">{errValidation.errors.password[0]}</p>
            )}
        </div>
        <div>
        <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Se connecter
        </button>
        </div>
    </form>
  );
}
export default LoginFormComponent;
