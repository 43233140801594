import React from "react";
import { isAuthenticated } from "services/authService";
import MenuProfilComponent from "./MenuProfileComponent";

function HeaderComponent() {
  const authenticated = isAuthenticated();

  return (
    <div className="bg-rose w-full border-y flex items-center justify-between px-4">
          <div className="flex items-center">
            <a href="https://converteo.com/strategie-pricing/" target="_blank" rel="noreferrer">
              <img
                className="h-auto w-60 mr-4"
                src="\Logos_RVB_Converteo_WEB.jpg"
                alt="Logo Converteo"
              />
            </a>
            <p className="font-semibold text-lg">Outil fourni par {""}
              <a
                href="https://converteo.com/strategie-pricing/"
                target="_blank"
                className="hover:underline"
                rel="noreferrer"
              >
              Converteo
              </a>
            </p>
          </div>
          {authenticated && <MenuProfilComponent />}
        </div>
      );
}
export default HeaderComponent;
