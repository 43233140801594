import React from "react";
import { Link } from "react-router-dom";

function EntryPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-1/2">
            <img
                className="mx-auto h-20 w-auto"
                src="\Logos_RVB_Converteo_WEB.jpg"
                alt="Logo Converteo"
            />
            <h2 className="my-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
                Bienvenue sur ScoreCard
            </h2>
            <p className="text-center">Découvrez notre nouvel outil, conçu pour évaluer le besoin de gestion du changement dans votre entreprise. En remplissant ce questionnaire, vous obtenez un score qui vous indique l'urgence de ce besoin. Partagez les résultats avec vos collègues afin de recueillir leurs diverses perspectives, et obtenez une vision claire des besoins de changement au sein de votre entreprise. Cet outil est tout particulièrement conçu pour évaluer les besoins en gestion du changement de ceux qui lancent un projet de tarification, et il peut vous aider à justifier vos demandes de ressources et de budget auprès de la direction.</p>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm my-10 flex justify-center">
            <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2">
                <Link to="/project/create">Démarrer un projet</Link>
            </button>
            <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2">
                <Link to="/login">Suivre un projet existant</Link>
            </button>

        </div>
    </div>
  );
}
export default EntryPage;
