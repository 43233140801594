import SignUpForm from "form-models/SignUpForm";
import { getByUrl, postByUrl, updateByUrl } from "./services";
import UserForm from "form-models/UserForm";
import Cookies from "js-cookie";

const userUrl = "api/user";

export const registration = async (userData: SignUpForm) => {
  try {
    const url = `${userUrl}/register`;
    const response = await postByUrl(url, userData);
    // Stocker le token dans le localStorage
    if (response.access_token) {
      const httpOnlyFlag = process.env.REACT_APP_API_HTTPONLY === 'true';
      Cookies.set('accessToken', response.access_token, { expires: 700, secure: false, httpOnly: httpOnlyFlag});
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const getMe = async () => {
  try {
    const url = `${userUrl}/me`;

    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData: UserForm, userId: string) => {
  try {
    const url = `${userUrl}/${userId}`;
    return updateByUrl(url, userData);
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const url = `${userUrl}/logout`;

    return postByUrl(url);
  } catch (error) {
    throw error;
  }
};